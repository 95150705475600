<template>
  <div>
    <v-alert
      v-model="mailUnsentAlert"
      dense
      top
      type="warning"
      prominent
    >
    <div class="d-flex align-center justify-space-between">
      <p class="font-weight-bold mt-4" style="font-size: large;">Kunde ej skicka bekräftelsemail</p>
      <v-btn
            text
            color="#fffffa"
            @click="mailUnsentAlert = false"
            >
            &#x2716;
          </v-btn>
    </div>
      
    </v-alert>

    <WicLogo />

    <BookingCalendar
      :roomsWithEventsProp="roomsWithEvents"
      @openBookingForm="openBookingForm"
      @emitGetBookings="getBookings"
      @emitSelectedValue="setSelectedValue"
    />

    <v-dialog v-model="dialog.visible" persistent max-width="1300px">
      <BookingForm
        :booking="dialog.bookingModel"
        :rooms="roomsResponse"
        @closedDialog="closedDialog"
        @formSaved="formSaved"
      />
    </v-dialog>
      <v-snackbar
        v-model="bookingSnackbar"
        :timeout="4000"
        color="#007d58" 
        top
        :multi-line="true"
        class="mt-15"
        >
        <div class="row justify-center">
          <p class="font-weight-bold mt-4" style="color: #fffffa;font-size: large;">{{ bookingSnackbarMessage }}</p>
        </div>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#fffffa"
            v-bind="attrs"
            @click="bookingSnackbar = false"
            >
            &#x2716;
          </v-btn>
        </template>
      </v-snackbar>
      
  </div>

</template>

<script>
import WicLogo from "@/components/WicLogo.vue";
import axios from "axios";
import BookingCalendar from "@/components/BookingCalendar.vue";
import BookingForm from "@/components/BookingForm.vue";

export default {
  components: {
    WicLogo,
    BookingCalendar,
    BookingForm,
  },

  data() {
    return {
      roomsResponse: [],
      bookingsResponse: [],
      dialog: {
        visible: false,
        bookingModel: null,
      },

      bookingSnackbar: false,
      bookingSnackbarMessage: "",
      mailUnsentAlert: false,
      selectedValue: "",
      roomsWithEvents: [],
      focusedEventColors: [
        "red darken-1",
        "pink darken-1",
        "purple darken-1",
        "indigo darken-1",
        "light-blue darken-1",
        "teal darken-1",
        "lime darken-1",
        "amber darken-1",
        "deep-orange darken-1",
      ],
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },

    getUserName() {
      return this.$store.getters.getUserName;
    },

    getToken() {
      return this.$store.getters.getToken;
    },
  },

  beforeMount() {
    this.getRooms();
    console.log(process.env['VUE_APP_API_URL']);
    console.log(process.env.VUE_APP_API_URL);
    console.log(process.env.NODE_ENV);
    console.log(process.env.PORT);
  },

  watch: {
    roomsResponse() {
      this.mapToRoomsWithEvents();
    },

    bookingsResponse() {
      this.fillEvents();
    },

  },

  methods: {
    getRooms() {
      axios
        .get("api/room")
        .then((response) => {
          this.roomsResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBookings() {
      axios
        .get("api/booking", {
          params: {
            fromDate: `${this.$moment(this.selectedValue)
              .startOf("week")
              .format("YYYY-MM-DD")}T00:00:00`,
            toDate: `${this.$moment(this.selectedValue)
              .add(1, "w")
              .startOf("week")
              .format("YYYY-MM-DD")}T23:59:59`,
            statuses: "Accepted",
            allCustomers: true
          },
        })
        .then((response) => {
          this.bookingsResponse = response.data.items;
        });
    },

    formSaved(bookingSuccessMessage, mailUnsent){
      this.dialog.visible = false;
      this.getBookings();
      this.mailUnsentAlert = mailUnsent;
      this.bookingSnackbarMessage = bookingSuccessMessage;
      this.bookingSnackbar = true;
      window.scrollTo(0,0);
    },

    setSelectedValue(value) {
      this.selectedValue = value;
    },

    mapToRoomsWithEvents() {
      this.roomsWithEvents = this.roomsResponse.map((x) => ({
        ...x,
        events: [],
      }));
    },

    fillEvents() {
      if (!this.roomsWithEvents || !this.bookingsResponse) return; //If either of rooms or bookings hasn't been retrieved yet then do nothing.

      this.roomsWithEvents = this.roomsWithEvents.map((x) => ({
        ...x,
        events: this.bookingsResponse
          .filter((b) => b.occurrances.some((o) => o.roomId == x.id)) // Select all bookings with occurrances with roomid same as the id of the room being iterated.
          .map((booking) =>
            booking.occurrances // For every booking selected iterate on occurrances...
              .filter((o) => o.roomId == x.id) //Filter so only occurrance with correct roomid gets mapped.
              .map((occurrance) => ({
                // ...and create an event-object for every occurrance to create an event.
                
                bookingModel: booking,
                roomId: occurrance.roomId,
                activityId: occurrance.activityId,
                activityName: occurrance.activity
                  ? occurrance.activity.name
                  : "",
                name: booking.customer ? booking.customer.name : "",
                start: this.$moment(occurrance.startTime).format(
                  "YYYY-MM-DD HH:mm"
                ),
                end: this.$moment(occurrance.endTime).format(
                  "YYYY-MM-DD HH:mm"
                ),
                notes: booking.notes,
                color: this.focusedEventColors[occurrance.activityId - 1],
              }))
          )
          .flat(), // From pagination
      }));
    },

    openBookingForm(dialog) {
      this.dialog = dialog;
    },

    closedDialog() {
      this.getBookings();
      this.dialog = {
        visible: false,
        bookingModel: {},
      };
    },
  },
};
</script>

