<template>
  <v-card>
    <v-card-title>
      <v-row class="d-flex align-center">
        <v-col class="3">
          <v-select
            v-model="selectedCustomerId"
            :items="customers"
            label="Välj kunder"
            item-text="name"
            item-value="id"
            hide-details
            outlined
            dense
            @input="inputUpdated()"
          ></v-select>
        </v-col>

        <v-col class="3">
          <v-select
            v-model="isDebited"
            :items="debitedOptions"
            label="Status"
            item-text="name"
            item-value="value"
            hide-details
            outlined
            dense
            @input="inputUpdated()"
          ></v-select>
        </v-col>

        <v-col class="3">
          <v-menu
            v-model="searchDates.fromDialog"
            :close-on-content-click="false"
            :nudge-right="33"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDates.fromDate"
                label="Från datum"
                prepend-icon="mdi-calendar"
                readonly
                hide-details
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDates.fromDate"
              :max="searchDates.toDate"
              @input="(searchDates.fromDialog = false), getBookings()"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col class="3">
          <v-menu
            v-model="searchDates.toDialog"
            :close-on-content-click="false"
            :nudge-right="33"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDates.toDate"
                label="Till datum"
                prepend-icon="mdi-calendar"
                readonly
                hide-details
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDates.toDate"
              :min="searchDates.fromDate"
              @input="(searchDates.toDialog = false), getBookings()"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :items="bookingsResponse.items"
      :headers="headers"
      :options.sync="bookingsPagingOptions"
      :server-items-length="bookingsResponse.totalCount"
      
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.name" @click="handleBooking(item.id, $event)">
            <td>{{ item.customer.name }}</td>

            <td style="width: 300px">
              <span v-for="occurrance in item.occurrances" :key="occurrance.id">
                {{ $moment(occurrance.startTime).format("YYYY-MM-DD HH:mm") }}
                --
                {{ $moment(occurrance.endTime).format("YYYY-MM-DD HH:mm")
                }}<br />
              </span>
            </td>

            <td>
              <span v-for="occurrance in item.occurrances" :key="occurrance.id">
                {{ occurrance.room.name }}<br />
              </span>
            </td>

            <td>
              <span v-for="occurrance in item.occurrances" :key="occurrance.id">
                {{ occurrance.activity.name }}<br />
              </span>
            </td>

            <td>
              {{
                getPricePerBooking(item.occurrances, item.customer.defaultPrice)
              }}
            </td>

            <td @click.stop="">
              <v-switch
                v-model="item.debited"
                :label="item.debited ? `Debiterad` : `Ej Debiterad`"
                @change="editBooking(item)"
              ></v-switch>
            </td>
            <td>
              <v-chip
                v-if="item.status == 'Accepted'"
                color= "#f5f5f5"
                class="green--text text--darken-1"
                >
                  Accepterad
              </v-chip>
              <v-chip
                v-else-if="item.status == 'Rejected'"
                color= "#f5f5f5"
                class="red--text text--darken-1"
                >
                  Nekad
              </v-chip>
              <v-chip
                v-else-if="item.status == 'Unhandled'"
                color= "#f5f5f5"
                class="blue-grey--text text--darken-1"
                >
                  Ohanterad
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn bottom absolute color="primary" @click="editBookings()"
        >Ändra status</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "BillingPagination",

  data() {
    return {
      selectedCustomerId: -1,
      customers: [],
      customersResponse: [],

      debitCheckbox: null,

      isDebited: false,
      debitedOptions: [
        { name: "Ej debiterade", value: false },
        { name: "Debiterade", value: true },
        { name: "Alla", value: null },
      ],
      bookingsResponse: {},

      customerNotSpecified: {
        name: "- Ej Specificerad -", value: null
      },

      bookingsPagingOptions: { page: 1, itemsPerPage: 15 },

      searchDates: {
        fromDate: this.$moment().format("YYYY-MM-DD"),
        fromDialog: false,
        toDate: this.$moment().add(1, "w").format("YYYY-MM-DD"),
        toDialog: false,
      },

      headers: [
        { text: "Kund", align: "left", sortable: false },
        { text: "Tid", sortable: false },
        { text: "Lokal", sortable: false },
        { text: "Aktivitet", sortable: false },
        { text: "Pris", sortable: false },
        { text: "Status", sortable: false },
        { text: "Hanterad", sortable: false },
      ],
    };
  },

  beforeMount() {
    this.getCustomerNames();
  },

  watch: {

    customersResponse() {
      this.customers = [...this.customersResponse];
      this.customers.unshift({ name: this.customerNotSpecified.name, id: this.customerNotSpecified.value });
      this.customers.unshift({ name: "Alla", id: -1 });
    },

    bookingsPagingOptions: {   
      deep: true,
      handler() {
        this.getBookings();
      },
    },
  },

  methods: {
    getCustomerNames() {
      axios
        .get("api/customer/allnames")
        .then((response) => {
          this.customersResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getBookings() {
      axios
        .get("api/booking", {
          params: {
            // prettier-ignore
            "fromDate": `${this.searchDates.fromDate}T00:00:00`,
            // prettier-ignore
            "toDate": `${this.searchDates.toDate}T23:59:59`,
            // prettier-ignore
            "customerId": this.selectedCustomerId,
            // prettier-ignore
            "allCustomers": this.selectedCustomerId === -1 ? true : false,
            // prettier-ignore
            "showDebited": this.isDebited,
            "paging.pageIndex": this.bookingsPagingOptions.page - 1,
            "paging.pageSize": this.bookingsPagingOptions.itemsPerPage,
            "paging.getAll": this.bookingsPagingOptions.itemsPerPage === -1 ? true : false,
          },
        })
        .then((response) => {
          this.bookingsResponse = response.data 
          this.bookingsResponse.items.map((x) => {
            if(!x.customer){
              x.customer = { id: this.customerNotSpecified.value, name: this.customerNotSpecified.name, defaultPrice: NaN }
            }
          })               
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editBookings() {
      axios
        .put("api/booking", this.bookingsResponse.items)
        .then(() => {
          this.getBookings();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editBooking(booking) {
      axios
        .put(`api/booking/${booking.id}`, booking)
        .then(() => {
          this.getBookings();
        })
        .catch((error) => {
          console.log(error);
        })
    },

    setDatesToNull() {
      this.searchDates.fromDate = null;
      this.searchDates.toDate = null;
    },

    inputUpdated() {
      if (
        (this.selectedCustomerId,
        this.searchDates.fromDate,
        this.searchDates.toDate)
      ) {
        this.getBookings();
      } else {
        this.bookingsResponse = {};
      }
    },

    getPricePerBooking(occurrances, customerPrice) {
      let totalPrice = 0;
      for (let occurrance of occurrances) {
        let price =
          customerPrice *
          this.$moment(occurrance.endTime).diff(
            this.$moment(occurrance.startTime),
            "hours",
            true
          );
        totalPrice += price;
      }

      return totalPrice;
    },

    handleBooking(bookingId){    
      this.$router.push({name: "BookingsConfirm", params: {bookingId: bookingId.toString(), fromComponent: this.$router.currentRoute.name}});
    }
  },
};
</script>
