<template>
  <v-card>
    <v-card-title>
      <!-- {{ user.id }} -->
    </v-card-title>
    <v-form @submit.prevent="onSubmit">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="user.name"
              label="Namn"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="user.userName"
              label="Användarnamn"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="changePassword"
              :label="`Ändra lösenord`"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="12" v-if="changePassword">
            <v-text-field
              v-model="user.oldPassword"
              label="Nuvarande lösenord"
              outlined
              hide-details
              autocomplete="null"
              :type="'password'"
            ></v-text-field>         
          </v-col>
          <v-col cols="12" v-if="changePassword">
            <v-text-field
              v-model="user.newPassword"
              label="Nytt lösenord"
              outlined
              hide-details
              :type="'password'"
            ></v-text-field>         
          </v-col>
          <v-col cols="12" v-if="changePassword">
            <v-text-field
              v-model="user.confirmPassword"
              label="Bekräfta lösenord"
              outlined
              hide-details
              :type="'password'"
            ></v-text-field>         
          </v-col>
          
          <v-col>
            <v-alert :value="isError" type="error">
              <div
                v-for="(error, index) in responseError"
                :key="`error_${index}`"
              >
                {{ error }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn type="submit" color="primary"> Spara ändringar </v-btn>
        <v-btn color="secondary" @click="closeDialog"> Avbryt </v-btn>
        <v-btn color="error" @click="openDeleteDialog"> Ta bort </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
export default {
  props: {
    value: { Type: Object, default: () => {} },
    isError: { Type: Boolean, default: () => false },
    responseError: { Type: Array, default: () => [] },
  },

  data() {
    return {
      user: this.value,
      changePassword: false,
    };
  },

  watch: {
    value() {
      this.user = this.value;
    },

    user() {
      // if (this.user.userName == this.value.userName) {
      //   this.user = { name: this.user.name };
      // }
      this.$emit("input", this.user);
    },
  },

  methods: {
    onSubmit() {
      this.$emit("saveChanges", this.changePassword);
    },

    closeDialog() {
      this.$emit("closeEditDialog");
    },

    openDeleteDialog() {
      this.$emit("openDeleteDialog");
    },
  },
};
</script>
