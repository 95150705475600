<template>
  <div class="flex-column" style="width: 100%">
    <CustomerPagination />
  </div>
</template>

<script>
import CustomerPagination from "@/components/CustomerPagination.vue";

export default {
  components: {
    CustomerPagination,
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
  },
};
</script>
