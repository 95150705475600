import axios from "axios";
import router from "@/router";

const state = {
  userName: null,
  token: null,
  expires: null,
  adminClaim: null,
  // userClaim: null,
};

const getters = {
  isAuthenticated: (state) => !!state.userName,
  getUserName: (state) => state.userName,
  getToken: (state) => state.token,
  isAdmin: (state) => state.adminClaim,
  getExpire: (state) => state.expires,
  // isUser: (state) => state.userClaim,
};

const actions = {
  async LogIn({ commit, dispatch }, loginModel) {
    // { commit }, same as context.commit here.
    await axios
      .post("api/auth/login", loginModel)
      .then(async (response) => {
        // At login success dispatch ParseJwt, commit setUser and push to route "/AdminPage"
        await dispatch("ParseJwt", response.data);
        await commit("setUser", response.data);
        await dispatch("loggedInChecker");
        // this.$router.push("/AdminPage");
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },

  async LogOut({ commit }) {
    commit("logOut");
    router.push("/admin");
  },

  async ParseJwt({ commit }, data) {
    var base64Url = data.token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    var decodedJson = JSON.parse(jsonPayload);

    await commit("setClaims", decodedJson);
  },

  async loggedInChecker({ dispatch, getters, state }) {
    if (getters.isAuthenticated === false) {
      return;
    }
    var expiresDate = new Date(state.expires);
    var now = new Date();
    var authExpired = expiresDate < now;
    if (authExpired) {
      await dispatch("LogOut");
      await alert("You have been logged out due to authentication expired.");
      return;
    }

    var difSeconds = (expiresDate.getTime() - now.getTime()) / 1000;

    setTimeout(async () => {
      await dispatch("loggedInChecker");
    }, (difSeconds * 1000) / 2);
  },
};

const mutations = {
  authinitialize(state) {
    localStorage.setItem("authtoken", state.token);
  },
  setUser(state, { userName, token, expires }) {
    state.userName = userName;
    state.token = token;
    state.expires = expires;
    localStorage.setItem("authtoken", token);
  },
  setClaims(state, decodedJson) {
    state.adminClaim = decodedJson.Admin;
    // state.userClaim = decodedJson.User;
  },
  logOut(state) {
    state.userName = null;
    state.token = null;
    state.expires = null;
    state.adminClaim = null;
    // state.userClaim = null;
    localStorage.setItem("authtoken", null);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
