<template>
  <div>
    <WicLogo />

    <LoginForm />
  </div>
</template>

<script>
import WicLogo from "@/components/WicLogo.vue";
import LoginForm from "@/components/LoginForm";

export default {
  components: {
    WicLogo,
    LoginForm,
  },
};
</script>
