<template>
  <v-row class="justify-center">
    <v-col cols="12" sm="8" md="6" lg="4" xl="3">
      <v-card>
        <v-card-title>
          <span class="text-h5">Admin Login</span>
        </v-card-title>
        <v-form @submit.prevent="onSubmit">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="loginModel.userName"
                  label="Användarnamn"
                  filled
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="loginModel.password"
                  label="Lösenord"
                  type="password"
                  filled
                  outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <v-alert :value="isError" type="error">
                  <div
                    v-for="(error, index) in responseError"
                    :key="`error_${index}`"
                  >
                    {{ error }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn type="submit" color="primary">Logga in</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LogIn",

  data() {
    return {
      loginModel: {
        userName: "",
        password: "",
      },
      isError: false,
      responseError: null,
      loginResponse: null,
    };
  },

  watch: {
  },

  methods: {
    ...mapActions(["LogIn"]),

    async onSubmit() {
      try {
        await this.LogIn(this.loginModel);
        this.isError = false;
        this.$router.push("/bookings/calendar");
      } catch (error) {
        if (Array.isArray(error.response.data)) {
          this.responseError = error.response.data;
        } else {
          this.responseError = [];
          Object.keys(error.response.data).forEach((p) => {
            if (Array.isArray(error.response.data[p])) {
              error.response.data[p].forEach((errorMessage) => {
                this.responseError.push(errorMessage);
              });
            }
          });
        }
        this.isError = true;
      }
    },
  },
};
</script>
