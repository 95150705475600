<template>
  <div>
    <WicLogo />
    <RegisterForm />
  </div>
</template>

<script>
import WicLogo from "@/components/WicLogo.vue";
import RegisterForm from "@/components/RegisterForm.vue";

export default {
  name: "RegisterAdmin",

  components: {
    WicLogo,
    RegisterForm,
  },
};
</script>
