<template>
  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <v-card
      ><v-card-title> </v-card-title>
      <v-card-text>
        <div class="body-1">
          Är du säker på att du vill ta bort bokning av
          <span class="font-weight-bold">
            {{ selectedEventProp.name }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="hideDeleteDialog"> Avbryt </v-btn>
        <v-btn color="error" @click="deleteBooking"> Ja </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "DeleteBookingDialog",

  props: {
    deleteDialogProp: { type: Boolean, default: () => false },
    selectedEventProp: { type: Object, default: () => {} },
  },

  watch: {
    deleteDialogProp() {
      this.deleteDialog = this.deleteDialogProp;
    },
  },

  data() {
    return {
      deleteDialog: false,
    };
  },

  methods: {
    deleteBooking() {
      axios
        .delete(
          `api/booking/${this.selectedEventProp.bookingModel.id}`
        )
        .then(() => {
          this.hideDeleteDialog();
          this.$emit("emitGetBookings");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    hideDeleteDialog(){
      this.deleteDialog = false;
      this.$emit("hideDeleteDialog");
    }
  },
};
</script>
