<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(occurrance, index) in occurrances"
      :key="`occurrance_${index}`"
    >
      <v-expansion-panel-header>
        {{ occurrance.date }} {{ occurrance.startTime }} -
        {{ occurrance.endTime }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" sm="4">
            <v-menu
              v-model="occurrance.dateDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="occurrance.date"
                  label="Datum"
                  prepend-icon="mdi-calendar"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="occurrance.date"
                @input="occurrance.dateDialog = false"
                first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" sm="4">
            <v-menu
              ref="menu"
              v-model="occurrance.startTimeDialog"
              :close-on-content-click="false"
              :nudge-right="30"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="occurrance.startTime"
                  label="Starttid"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="occurrance.startTime"
                full-width
                format="24hr"
                :allowed-minutes="allowedStep"
                :max="occurrance.endTime"
                min="07:00"
              ></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="6" sm="4">
            <v-menu
              ref="menu"
              v-model="occurrance.endTimeDialog"
              :close-on-content-click="false"
              :nudge-right="30"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="occurrance.endTime"
                  label="Sluttid"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="occurrance.endTime"
                full-width
                format="24hr"
                :allowed-minutes="allowedStep"
                :min="occurrance.startTime"
                max="23:59"
              ></v-time-picker>
            </v-menu>
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="occurrance.roomId"
              :items="rooms"
              label="Lokal"
              item-text="name"
              item-value="id"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="occurrance.activityId"
              :items="activities"
              label="Aktivitet"
              item-text="name"
              item-value="id"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="2">
            <v-text-field
              v-model="occurrance.repeat"
              label="Upprepa antal veckor"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="accent"
              outlined
              icon
              @click="removeOccurrance(index)"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            v-for="repeat in getRepeatDays(occurrance)"
            :key="`occ_${repeat.index}`"
          >
            <v-card>
              <v-card-text class="body-2 text-center">
                {{ repeat.startTime.local().format("YYYY-MM-DD") }}
                {{ repeat.startTime.local().format("HH:mm") }}
                -
                {{ repeat.endTime.local().format("HH:mm") }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    value: { Type: Array, default: () => [] },
    rooms: { Type: Array, default: () => [] },
    activities: { Type: Array, default: () => [] },
  },

  data() {
    return {
      occurrances: this.value,
    };
  },

  watch: {
    value() {
      this.occurrances = this.value;
    },

    occurrances: {
      deep: true,
      handler() {
        this.$emit("input", this.occurrances);
      },
    },
  },

  methods: {
    addOccurrance(startTime = "", endTime = "", date = "") {
      this.occurrances.push({
        id: null,
        bookingId: null,
        roomId: this.roomId,
        room: {},
        activityId: null,
        activity: {},
        date: date,
        startTime: startTime,
        endTime: endTime,
        startTimeDialog: false,
        endTimeDialog: false,
        dateDialog: false,
      });
      this.$emit("input", this.occurrances);
    },

    removeOccurrance(index) {
      this.occurrances.splice(index, 1);
      this.$emit("input", this.occurrances);
    },

    getRepeatDays(occurrance) {
      var firstStartTime = this.$moment(
        occurrance.date + " " + occurrance.startTime
      );
      var firstEndtime = this.$moment(
        occurrance.date + " " + occurrance.endTime
      );
      var days = Array.from({ length: occurrance.repeat })
        .map((_, index) => index + 1)
        .map((weekAdd) => ({
          startTime: firstStartTime.clone().add(weekAdd, "week"),
          endTime: firstEndtime.clone().add(weekAdd, "week"),
        }));
      return days;
    },

    allowedStep: (m) => m % 15 === 0,
  },
};
</script>

<style scoped>
.v-card.v-sheet.theme--light {
  border-style: solid !important;
  border-width: 2px !important;
  border-color: #19336e !important;
}
</style>
