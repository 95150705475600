<template>
  <v-card>
    <v-card-title>
      <div
        :style="{
          color:
            bookingModel.status == 'Accepted'
              ? '#43A047'
              : bookingModel.status == 'Rejected'
              ? '#E53935'
              : '#546E7A',
        }"
      >
        <div v-if="bookingModel.customer">
          {{ bookingModel.customer.name }}
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <div class="body-1">
        <span
          v-if="bookingModel.status == 'Accepted'"
          class="green--text text--darken-1"
        >
          Accepterad
        </span>
        <span
          v-else-if="bookingModel.status == 'Rejected'"
          class="red--text text--darken-1"
        >
          Nekad
        </span>
        <span
          v-else-if="bookingModel.status == 'Unhandled'"
          class="blue-grey--text text--darken-1"
        >
          Ohanterad
        </span>
      </div>

      <br />

      <div v-for="occurrance in bookingModel.occurrances" :key="occurrance.id">
        <div class="font-weight-bold body-1">
          {{ $moment(occurrance.startTime).format("YYYY-MM-DD HH:mm") }}
          --
          {{ $moment(occurrance.endTime).format("YYYY-MM-DD HH:mm") }}
        </div>
        <div>
          <span class="body-1"> Aktivitet: </span>
          <span class="font-weight-bold body-1">
            {{ occurrance.activity.name }}
          </span>
        </div>
        <div>
          <span class="body-1"> Lokal: </span>
          <span class="font-weight-bold body-1">
            {{ occurrance.room.name }}
          </span>
        </div>
        <br />
      </div>
      <div>
        <span class="body-1"> Kontakt namn: </span>
        <span class="font-weight-bold body-1">
          {{ bookingModel.contactName }}
        </span>
      </div>
      <br />
      <div>
        <span class="body-1"> Senast hanterad av: </span>
        <span v-if="bookingModel.handledByUser" class="font-weight-bold body-1">
          {{ bookingModel.handledByUser.userName }}
        </span>
        <span v-else class="font-weight-bold body-1"> Ohanterad </span>
      </div>
      <div>
        <span class="body-1"> Hanteringsdatum: </span>
        <span v-if="bookingModel.handledDate" class="font-weight-bold body-1">
          {{ $moment(bookingModel.handledDate).format("YYYY-MM-DD HH:mm") }}
        </span>
        <span v-else class="font-weight-bold body-1"> Ohanterad </span>
      </div>
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn
        color="green darken-1 white--text"
        outlined
        @click="acceptBooking()"
        >Acceptera</v-btn
      > -->
      <!-- <v-btn color="error" outlined @click="rejectBooking()">Neka</v-btn> -->
      <v-btn
        color="blue-grey darken-1 white--text"
        outlined
        @click="unhandleBooking()"
        >Markera som ohanterad</v-btn
      >
      <!-- <v-btn color="blue darken-1"  @click="openEditDialog()">Redigera</v-btn> -->
      <v-btn
        color="primary"
        :to="'/bookings' + '/confirm' + `/${bookingModel.id}`"
        >Hantera</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "BookingHandle",

  props: {
    bookingModel: { type: Object, default: () => {} },
  },

  data() {
    return {};
  },

  methods: {
    acceptBooking() {
      let booking = {
        ...this.bookingModel,
        status: "Accepted",
      };

      this.editBooking(booking);
    },

    rejectBooking() {
      let booking = {
        ...this.bookingModel,
        status: "Rejected",
      };

      this.editBooking(booking);
    },

    unhandleBooking() {
      let booking = {
        ...this.bookingModel,
        status: "Unhandled",
      };

      this.editBooking(booking);
    },

    editBooking(booking) {
      let bookingCommand = {
        ...booking,
        customerId: booking.customer ? booking.customer.id : null,
      };
      axios
        .put(`api/booking/${bookingCommand.id}`, bookingCommand)
        .then(() => {
          // this.sendMail(booking.id)
          this.$emit("closeDialog");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // sendMail(bookingId){
    //   axios
    //     .post(`api/booking/sendmail${bookingId}`)
    //     .then((reponse) => {

    //     })
    // },

    openEditDialog() {
      this.$emit("openEditDialog");
    },
  },
};
</script>
