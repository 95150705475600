import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import UserLogin from "@/views/UserLogin.vue";
import BookingsCalendar from "@/views/BookingsCalendar.vue";
import CustomersHandle from "@/views/CustomersHandle.vue";
import UserRegister from "@/views/UserRegister";
import BookingsHandle from "@/views/BookingsHandle";
import BookingsBilling from "@/views/BookingsBilling";
import BookingsConfirm from "@/views/BookingsConfirm";
import UserHandle from "@/views/UserHandle";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {name: 'BookingsCalendar'}
  },
  {
    path: "/bookings/calendar",
    name: "BookingsCalendar",
    component: BookingsCalendar,
  },
  {
    path: "/admin",
    name: "UserLogin",
    component: UserLogin,
    meta: { isLoginPage: true },
  },
  {
    path: "/customers/handle",
    name: "CustomersHandle",
    component: CustomersHandle,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/register",
    name: "UserRegister",
    component: UserRegister,
    meta: { requiresAuth: true},
  },
  {
    path: "/bookings/handle",
    name: "BookingsHandle",
    component: BookingsHandle,
    meta: { requiresAuth: true },
  },
  {
    path: "/bookings/billing",
    name: "BookingsBilling",
    component: BookingsBilling,
    meta: { requiresAuth: true },
  },
  {
    path: "/bookings/confirm/:bookingId",
    name: "BookingsConfirm",
    component: BookingsConfirm,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/user/handle",
    name: "UserHandle",
    component: UserHandle,
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next("/admin");
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isLoginPage)) {
    if (store.getters.isAuthenticated) {
      next("/booking/calendar");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
