<template>
  <v-card>
    <v-card-title>
      <v-row class="d-flex align-center">
        <v-col>
          <v-select
            v-model="statusParams"
            :items="statuses"
            label="Välj status"
            item-text="name"
            item-value="value"
            outlined
            multiple
            dense
            hide-details
            clearable
            @input="inputUpdated()"
          ></v-select>
        </v-col>

        <v-col>
          <v-menu
            v-model="searchDates.fromDialog"
            :close-on-content-click="false"
            :nudge-right="33"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDates.fromDate"
                label="Från datum"
                prepend-icon="mdi-calendar"
                readonly
                dense
                hide-details
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDates.fromDate"
              :max="searchDates.toDate"
              @input="(searchDates.fromDialog = false), getBookings()"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col>
          <v-menu
            v-model="searchDates.toDialog"
            :close-on-content-click="false"
            :nudge-right="33"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchDates.toDate"
                label="Till datum"
                prepend-icon="mdi-calendar"
                readonly
                dense
                hide-details
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchDates.toDate"
              :min="searchDates.fromDate"
              @input="(searchDates.toDialog = false), getBookings()"
              first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :items="bookingsResponse.items"
      :headers="headers"
      :options.sync="bookingsPagingOptions"
      :server-items-length="bookingsResponse.totalCount"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.name"
            :style="{
              'background-color':
                item.status == 'Accepted'
                  ? '#C8E6C9'
                  : item.status == 'Rejected'
                  ? '#FFCDD2'
                  : '#E0E0E0',
              'cursor': 'pointer',
            }"
            @click="handleClickedBooking(item)"
          >
            <td>{{ item.id }}</td>
            <td style="width: 400px">
              <span v-for="occurrance in item.occurrances" :key="occurrance.id">
                {{ $moment(occurrance.startTime).format("YYYY-MM-DD HH:mm") }}
                --
                {{ $moment(occurrance.endTime).format("YYYY-MM-DD HH:mm") }}
                <br
              /></span>
            </td>

            <td>
              <span v-for="occurrance in item.occurrances" :key="occurrance.id">
                {{ occurrance.room.name }}<br />
              </span>
            </td>

            <td>
              <span v-for="occurrance in item.occurrances" :key="occurrance.id">
                {{ occurrance.activity.name }}<br />
              </span>
            </td>

            <td>{{ item.contactName }}</td>

            <!-- <td>{{ item.customer.name }}</td> -->

            <td>
              <v-chip
                v-if="item.status == 'Accepted'"
                color= "#f5f5f5"
                class="green--text text--darken-1"
                >
                  Accepterad
              </v-chip>
              <v-chip
                v-else-if="item.status == 'Rejected'"
                color= "#f5f5f5"
                class="red--text text--darken-1"
                >
                  Nekad
              </v-chip>
              <v-chip
                v-else-if="item.status == 'Unhandled'"
                color= "#f5f5f5"
                class="blue-grey--text text--darken-1"
                >
                  Ohanterad
              </v-chip>
              
              <!-- <v-btn elevation="0" @click="handleClickedBooking(item)"
                ><span
                  v-if="item.status == 'Accepted'"
                  class="green--text text--darken-1"
                >
                  Accepterad
                </span>
                <span
                  v-else-if="item.status == 'Rejected'"
                  class="red--text text--darken-1"
                >
                  Nekad
                </span>
                <span
                  v-else-if="item.status == 'Unhandled'"
                  class="blue-grey--text text--darken-1"
                >
                  Ohanterad
                </span>
              </v-btn> -->
            </td>

            <!-- <td>
                  <v-simple-checkbox
                    v-model="item.accepted"
                  ></v-simple-checkbox>
                </td> -->
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <!-- <v-card-actions>
        <v-btn
          bottom
          absolute
          color="blue darken-1"
          text
          @click="editBookings()"
          >Change accepted status</v-btn
        >
      </v-card-actions> -->
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "BookingPagination",

  props: {
    getBookingsTrigger: { type: Boolean, default: () => null },
  },

  data() {
    return {
      bookingsResponse: {},

      bookingsPagingOptions: { page: 1, itemsPerPage: 15 },

      statusParams: null,
      statuses: [
        { name: "Ohanterad", value: "Unhandled" },
        { name: "Accepterad", value: "Accepted" },
        { name: "Nekad", value: "Rejected" },
      ],

      searchDates: {
        fromDate: this.$moment().format("YYYY-MM-DD"),
        fromDialog: false,
        toDate: this.$moment().add(1, "w").format("YYYY-MM-DD"),
        toDialog: false,
      },

      dialog: {},

      headers: [
        { text: "Id", align: "left", sortable: false },
        {
          text: "Tid",
          sortable: false,
        },
        { text: "Lokal", sortable: false },
        { text: "Aktivitet", sortable: false },
        { text: "Kontakt namn", sortable: false },
        { text: "Status", sortable: false },
      ],
    };
  },

  watch: {
    getBookingsTrigger() {
      this.getBookings();
    },

    bookingsPagingOptions: {
      deep: true,
      handler() {
        this.getBookings();
      },
    },
  },

  methods: {
    getBookings() {
      var statusesKey =
        this.statusParams !== null
          ? `${this.statusParams.map((x) => `&statuses=${x}`).join("")}`
          : "";
      var queryString = `?fromDate=${
        this.searchDates.fromDate
      }T00:00:00&toDate=${this.searchDates.toDate}T23:59:59&paging.pageIndex=${
        this.bookingsPagingOptions.page - 1
      }&paging.pageSize=${
        this.bookingsPagingOptions.itemsPerPage
      }${statusesKey}
      &allCustomers=true`;

      axios
        .get("api/booking" + queryString)
        .then((response) => {
          this.bookingsResponse = response.data;
          this.mapBooking();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editBookings() {
      axios
        .put("api/booking", this.bookingsResponse.items)
        .then(() => {
          this.getBookings();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mapBooking() {
      this.bookings = this.bookingsResponse.items.map((i) => ({
        ...i,
        formattedOccurrances: i.occurrances.map((o) => ({
          startTime: this.$moment(o.startTime).format("YYYY-MM-DD HH:mm"),
          endTime: this.$moment(o.endTime).format("YYYY-MM-DD HH:mm"),
        })),
      }));
    },

    inputUpdated() {
      if ((this.searchDates.fromDate, this.searchDates.toDate)) {
        this.getBookings();
      } else {
        this.bookingsResponse = {};
      }
    },

    handleClickedBooking(item) {
      this.dialog = {
        visible: true,
        bookingModel: item,
      };
      this.$emit("emitItem", this.dialog);
    },
  },
};
</script>
