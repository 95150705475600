<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col>
          <v-text-field
            v-model="searchString"
            label="Sök"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col>
          <v-btn color="primary" @click="getUsers">
            <v-icon>mdi-account-search</v-icon></v-btn
          >
        </v-col>
        <v-col class="d-flex justify-center">
          <v-btn color="primary" dark to="/user/register"
            >Lägg till admin</v-btn
          >
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
      :items="usersResponse.items"
      :headers="headers"
      :options.sync="usersPagingOptions"
      :server-items-length="usersResponse.totalCount"
      @click:row="handleRowClick($event, clickedUser)"
    >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "UserPagination",

  data() {
    return {
      searchString: "",

      usersResponse: {},
      usersPagingOptions: { page: 1, itemsPerPage: 15 },
      headers: [
        { text: "Namn", align: "left", value: "name", sortable: false },
        { text: "Användarnamn", value: "userName", sortable: false },
      ],
      clickedUser: {},
    };
  },

  watch: {
    usersPagingOptions: {
      deep: true,
      handler() {
        this.getUsers();
      },
    },
  },

  methods: {
    getUsers() {
      axios
        .get("api/user", {
          params: {
            search: this.searchString,
          },
        })
        .then((response) => {
          this.usersResponse = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addUser() {},

    handleRowClick(clickedUser) {
      var user = { ...clickedUser };
      this.$emit("clickedRow", user);
    },
  },
};
</script>
