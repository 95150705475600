import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import axios from "axios";

const moment = require("moment");
require("moment/locale/sv");

axios.defaults.withCredentials = true; //Make axios to send all requests with credentials.
var appConfig = require('../public/appconfig.json')
axios.defaults.baseURL = appConfig.VUE_APP_API_URL;
axios.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("authtoken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(undefined, (error) => {
  if (error) {
    const originalRequest = error.config;

    // Reject with error if current route is "/LogIn"
    if (router.currentRoute.path === "/admin") {
      return Promise.reject(error);
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/admin");
    }
  }
  // Reject with error if response status is not 401
  return Promise.reject(error);
});

Vue.config.productionTip = false;

Vue.use(require("vue-moment"), {
  moment,
});

new Vue({
  store,
  router,
  vuetify,
  beforeCreate() {
    this.$store.commit("authinitialize");
  },
  render: (h) => h(App),
}).$mount("#app");
