<template>
  <v-row class="d-flex justify-center">
    <v-col>
      <v-row class="d-flex align-center">
        <v-spacer></v-spacer>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn color="primary" icon outlined :disabled="this.showDayFocus" @click="moveCalendar(-1)">
            <v-icon>mdi-chevron-double-left</v-icon></v-btn
          >
        </v-col>

        <v-col
          cols="8"
          sm="5"
          md="4"
          lg="3"
          xl="2"
          class="d-flex justify-center"
        >
          <v-select
            v-model="selectedValue"
            :items="items"
            label="Vecka"
            item-text="text"
            item-value="start"
            outlined
            hide-details 
            :disabled="this.showDayFocus"          
          ></v-select>
        </v-col>

        <v-col cols="2" class="d-flex justify-start">
          <v-btn color="primary" icon outlined :disabled="this.showDayFocus" @click="moveCalendar(1)"
            ><v-icon>mdi-chevron-double-right</v-icon></v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row v-if="this.showDayFocus" justify="center">
        <v-btn color="primary" outlined @click="focusOnDay(selectedValue, false)"><v-icon>mdi-calendar-month</v-icon> Återgå till veckokalender</v-btn>
      </v-row>

      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="6"
          xl="4"
          v-for="roomWithEvents in roomsWithEvents"
          :key="roomWithEvents.id"
        >
          <v-card elevation="5" class="primary white--text">
            <v-card-title>
              <span class="text-h5">{{ roomWithEvents.name }}</span>
            </v-card-title>
            <v-card-text>
              <v-sheet>
                <v-calendar
                  :value="selectedValue"
                  :events="roomWithEvents.events"
                  :type="calendarType"
                  :weekdays="startDay"
                  :weekday-format="getDay"
                  :interval-format="intervalFormat"
                  interval-width="45"
                  interval-height="20"
                  interval-count="17"
                  first-time="07.00"
                  event-text-color="white"
                  color="primary"
                  ref="calendar"
                  
                  style="max-height: 430px"
                  @click:time="timeClick($event, roomWithEvents.id)"
                  @click:event="showEvent"
                >
                  <template v-slot:day-label-header="{day, date}">
                    <div class="day-label-header">
                      <div class="day-label" @click="focusOnDay(date, true, $event)">
                        <span class="caption" >
                        {{ day }}
                        </span>
                      </div>
                      <div class="return-to-calender-btn">
                        <v-btn v-if="showDayFocus" color="primary" icon outlined @click="focusOnDay(date, false, $event)"><v-icon>mdi-calendar-month</v-icon></v-btn>
                      </div>
                    </div>
                  </template>

                  <template v-slot:event="{ event }">
                    <p style="margin: 3px; display: inline">
                      {{ event.name }}
                    </p>
                  </template>
                </v-calendar>

                <EventDialog
                  :selectedEventProp="selectedEvent"
                  :selectedElementProp="selectedElement"
                  :selectedOpenProp="selectedOpen"
                  @openBookingForm="openBookingForm"
                  @openDeleteDialog="openDeleteDialog"
                />
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <DeleteBookingDialog
        :deleteDialogProp="deleteDialog"
        :selectedEventProp="selectedEvent"
        @hideDeleteDialog="hideDeleteDialog"
        @emitGetBookings="emitGetBookings"
      />
    </v-col>
  </v-row>
</template>

<script>
import EventDialog from "@/components/EventDialog.vue";
import DeleteBookingDialog from "@/components/DeleteBookingDialog.vue";

export default {
  name: "BookingCalendar",

  components: {
    EventDialog,
    DeleteBookingDialog,
  },

  props: {
    roomsWithEventsProp: { type: Array, default: () => {} },
  },

  data() {
    return {
      startDay: [1, 2, 3, 4, 5, 6, 0],
      calendarType: "week",

      clickedTime: null,
      selectedValue: "",

      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      
      showDayFocus: false,

      deleteDialog: false,

      items: null,
      roomsWithEvents: [],
      dialog: {
        visible: false,
        bookingModel: null,
      },
    };
  },

  watch: {
    selectedValue() {
      this.$emit("emitSelectedValue", this.selectedValue);
      this.$emit("emitGetBookings");
    },

    rooms() {
      this.$emit("emitGetBookings");
    },

    roomsWithEventsProp() {
      this.mapRoomsWithEvents();
    },
  },

  mounted() {
    this.selectedValue = this.$moment().startOf("week").format("YYYY-MM-DD");

    var weeksForward = 52;
    var weeksBackward = 5;

    var startWeek = this.$moment()
      .startOf("week")
      .subtract(weeksBackward, "week");

    this.items = Array.from(
      { length: weeksForward + weeksBackward + 1 },
      (_, i) => startWeek.clone().add(i, "week")
    )
      .map((start) => ({
        week: start.format("ww"),
        start: start.clone().format("YYYY-MM-DD"),
        end: start.clone().endOf("week").format("YYYY-MM-DD"),
      }))
      .map((weekInfo) => ({
        ...weekInfo,
        text: `${weekInfo.week} (${weekInfo.start} - ${weekInfo.end})`,
      }));

  },

  beforeMount() {
    this.selectedValue = this.$moment().startOf("week").format("YYYY-MM-DD");
  },

  methods: {
    scrollCalendarsToTop() {
      this.roomsWithEventsProp.forEach((x, index) => {
        var calendar = this.$refs.calendar[index];
        calendar.scrollToTime("08:00");
      });
    },

    moveCalendar(moves, typeOfMove = null) {
      if (!typeOfMove) {
        typeOfMove = this.calendarType;
      }
      this.selectedValue = this.$moment(this.selectedValue)
        .add(moves, typeOfMove)
        .startOf(typeOfMove)
        .format("YYYY-MM-DD");
    },

    dateChanged(date) {
      console.log(date.start.date + " - " + date.end.date);
    },

    timeClick(clickedTime, roomId) {
      var dateTime = this.roundDownTime(clickedTime);
      this.dialog = {
        ...this.dialog,
        bookingModel: {
          occurrances: [
            {
              startTime: dateTime.date + " " + dateTime.time,
              endTime: "",
              roomId: roomId,
            },
          ],
        },
        visible: true,       
      };
      this.$emit("openBookingForm", this.dialog);
    },

    openBookingForm(dialog) {
      this.$emit("openBookingForm", dialog);
    },

    roundDownTime(clickedTime) {
      clickedTime.minute = 0;
      let time = clickedTime.time.split(":");
      clickedTime.time = `${time[0]}:00`;
      return clickedTime;
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    openDeleteDialog() {
      this.deleteDialog = true;
    },

    hideDeleteDialog(){
      this.deleteDialog = false;
    },

    emitGetBookings() {
      this.$emit("emitGetBookings");
    },

    mapRoomsWithEvents() {
      this.roomsWithEvents = this.roomsWithEventsProp;
    },

    intervalFormat(interval) {
      return interval.time;
    },

    getDay(date){
      const days = ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"];
      return days[date.weekday];
    },

    focusOnDay(date, focusBool){
      this.selectedValue = focusBool ? date : this.$moment(date).startOf("week").format("YYYY-MM-DD");
      this.calendarType = focusBool ? "day" : "week";
      this.showDayFocus = focusBool;
    }
  },
};
</script>

<style scoped>
.v-sheet {
  border-color: #cd8d37 !important;
  border-width: 2px;
  border-style: solid;
}

.day-label-header{
  display:grid;
  grid-template-columns: 30% auto 30%;
  grid-template-rows: auto;
  margin-bottom: 0.5rem;
}

.day-label-header .day-label{
  display:grid;
  grid-column: 2;
  grid-row: 1;
  align-content: center;
}

.day-label-header .return-to-calender-btn{
  display:grid;
  grid-column: 3;
  grid-row: 1;
  justify-content: center;
}

</style>
