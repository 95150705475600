<template>
  <v-row class="d-flex align-center">
    <v-spacer></v-spacer>
    <v-col class="col-6 d-flex justify-center">
      <v-img
        src="@/assets/Warberg_IC_logo.png"
        max-height="200"
        max-width="200"
        contain
      ></v-img>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
export default {
  name: "WicLogo",
};
</script>
