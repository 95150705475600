import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#19336E", // rgb(25,51,110)
        secondary: "#55C5F2", // rgb(85,197,242)
        accent: "#CD8D37", // rgb(205,141,55)
      },
    },
  },
});
