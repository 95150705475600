<template>
  <div class="flex-column justify-space-around" style="width: 100%">
    <UserPagination ref="userPagination" @clickedRow="openEditDialog" />

    <v-dialog persistent v-model="editDialog" max-width="450">
      <UserEditDialog
        v-model="user"
        :isError="isError"
        :responseError="responseError"
        @saveChanges="saveChanges"
        @closeEditDialog="closeEditDialog"
        @openDeleteDialog="openDeleteDialog"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="text-body-1">
            <span> Är du säker på att du vill ta bort </span>
            <span class="font-weight-bold">
              {{ user.userName }}
            </span>
          </div>
          <div>
            <span>Id: </span>
            <span class="font-weight-bold">
              {{ user.id }}
            </span>
          </div>
          <div>
            <span>Namn: </span>
            <span class="font-weight-bold">
              {{ user.name }}
            </span>
          </div>          
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="closeDeleteDialog"> Avbryt </v-btn>
          <v-btn color="error" @click="deleteUser"> Ta bort </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserPagination from "@/components/UserPagination.vue";
import UserEditDialog from "@/components/UserEditDialog.vue";
import axios from "axios";

export default {
  components: {
    UserPagination,
    UserEditDialog,
  },

  data() {
    return {
      editDialog: false,
      deleteDialog: false,
      user: {},
      userResponse: {},     
      newPasswordModel: {},

      isError: false,
      isPasswordError: false,
      responseError: null,
    };
  },

  watch: {
    isPasswordError(){
      this.isError = this.isPasswordError;
    }
  },

  methods: {
    openEditDialog(user) {
      this.editDialog = true;
      this.user = user;
    },

    saveChanges(passwordChange) {
      
      axios
        .put(`api/user/${this.user.id}`, this.user)
        .then((response) => {
          if(passwordChange){
            this.changePassword()
          }
          this.userResponse = response.data;
          this.$refs.userPagination.getUsers();
          if(!passwordChange){
            this.editDialog = false;
            this.isError = false;
          }         
        })
        .catch((error) => {
          console.error(error);
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
              else{
                //For identity errors.
                if(Array.isArray(error.response.data[p]["errors"])){
                  error.response.data[p]["errors"].forEach((e) => {
                    this.responseError.push(e.errorMessage);
                  })
                }
              }
            });
          }
          this.isError = true;
        });
    },

    changePassword(){
      this.newPasswordModel = {
        oldPassword: this.user.oldPassword,
        newPassword: this.user.newPassword,
        confirmPassword: this.user.confirmPassword
      }
      axios
       .put(`api/user/password/${this.user.id}`, this.newPasswordModel)
       .then(() => {
          this.editDialog = false;
          this.isPasswordError = false;
       })
       .catch((error) => {
          console.error(error);
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
              else{
                //For identity errors.
                if(Array.isArray(error.response.data[p]["errors"])){
                  error.response.data[p]["errors"].forEach((e) => {
                    this.responseError.push(e.errorMessage);
                  })
                }
              }
            });
          }
          this.isPasswordError = true;
       })
    },

    deleteUser() {
      axios
        .delete(`api/user/${this.user.id}`)
        .then((response) => {
          this.userResponse = response.data;
          this.$refs.userPagination.getUsers();
          this.deleteDialog = false;
          this.editDialog = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    closeEditDialog() {
      this.isError = false;
      this.responseError = {};
      this.editDialog = false;
    },

    openDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },
  },
};
</script>
