<template>
  <v-card>
    <v-card-title> </v-card-title>
    <v-form @submit.prevent="onSubmit">
      <v-card-text>
        <v-row justify="space-between">
          <v-col cols="6">
            <v-text-field
              v-model="customerModel.name"
              label="Organisations namn"
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="customerModel.orgOrPersonalNumber"
              label="Organisations nummer"
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="customerModel.defaultPrice"
              label="Standard pris per timme"
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="text-h6">Adress</div>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="customerModel.address"
              label="Adress"
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="6"> </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="customerModel.postalcode"
              label="Postnummer"
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field v-model="customerModel.city" label="Stad" required>
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="text-h6">Kontakt person</div>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="customerModel.customerContact"
              label="Namn"
              required
            >
            </v-text-field>
          </v-col>

          <v-col cols="6"> </v-col>

          <v-col cols="6">
            <v-text-field v-model="customerModel.email" label="Email" required>
            </v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="customerModel.phoneNumber"
              label="Telefonnummer"
              required
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-alert :value="isError" type="error">
              <div
                v-for="(error, index) in responseError"
                :key="`error_${index}`"
              >
                {{ error }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="closeDialog()"> Avbryt </v-btn>
        <v-btn color="primary" type="submit"> Spara </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "CustomerForm",

  props: {
    customer: { type: Object, default: () => {} },
  },

  data() {
    return {
      customerModel: {},

      customerResponse: "",

      responseError: [],
      isError: false,

      statusCode: null,
    };
  },

  watch: {
    customer() {
      this.setCustomerModel();
    },
  },

  mounted() {
    if (this.customer) {
      this.setCustomerModel();
    }
  },

  methods: {
    async onSubmit() {
      await this.postCustomer();
      if (this.statusCode == 201 || this.statusCode == 204) {
        this.resetCustomerModel();
        this.$emit("formSaved", this.customerResponse.id);
      }
    },

    setCustomerModel() {
      this.customerModel = {
        ...this.resetCustomerModel(),
        ...this.customer,
      };
    },

    resetCustomerModel() {
      return this.customerModel = {
        id: null,
        name: null,
        customerContact: null,
        email: null,
        address: null,
        postalcode: null,
        city: null,
        phoneNumber: null,
        orgOrPersonalNumber: null,
      };
    },

    async postCustomer() {
      var axiosRequest = null;
      if (this.customerModel.id) {
        axiosRequest = axios.put(
          `api/customer/${this.customerModel.id}`,
          this.customerModel
        );
      } else {
        axiosRequest = axios.post(
          "api/customer",
          this.customerModel
        );
      }

      await axiosRequest
        .then((response) => {
          this.customerResponse = response.data;
          this.closeInfoDialog();
          this.statusCode = response.status;
        })
        .catch((error) => {
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          this.isError = true;
          this.statusCode = error.response.status;
        });
    },

    closeDialog() {
      this.$emit("closedDialog");
      this.responseError = [];
      this.isError = false;
      this.customerResponse = {};
      this.resetCustomerModel();
    },

    closeInfoDialog() {
      this.$emit("closeInfoDialog");
    },
  },
};
</script>
