<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <div v-if="customerResponse">
              {{ customerResponse.name }}
            </div>
            <div v-else style="color: #ff5252;">
              Oregistrerad kund
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-card >
                  <v-card-title class="d-flex justify-space-between">
                    <div class="subtitle-2">Kund info</div>
                  </v-card-title>
                  <v-card-text>
                    <div class="d-flex flex-column flex-wrap" style="max-height: 20rem;">
                      <div
                       v-for="(value, title) in customerInfo"
                       :key="title">
                       <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title>{{ value }}</v-list-item-title>
                            <v-list-item-subtitle>{{title }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>            
                      </div>                    
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="d-flex align-center">
                <v-row class="d-flex flex-column">
                  <v-col class="d-flex justify-center">
                    <v-select
                      v-model="bookingModel.customerId"
                      :items="customersResponse"
                      label="Företag/lag*"
                      item-text="name"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex justify-center">
                    <v-btn color="primary" @click="addCustomerDialog = true">
                      Lägg till ny kund
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col>
                <v-text-field
                  v-model="bookingModel.notes"
                  label="Anteckningar"
                  hide-details
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <OccurranceExpPanelv2
                  ref="occurrancePanel"
                  v-model="bookingModel.occurrances"
                  :rooms="roomsResponse"
                  :activities="activitiesResponse"
                  @occurrancesUpdated="occurrancesUpdated"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  elevation="1"
                  @click="triggerAddOccurrance()"
                  >Lägg till tid</v-btn
                >
              </v-col>

              <v-col>
                <v-alert :value="isError" type="error">
                  <div
                    v-for="(error, index) in responseError"
                    :key="`error_${index}`"
                    >
                    {{ error }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                <v-card>
                  <v-card-title>
                    <div class="subtitle-2">Hantera status</div>
                  </v-card-title>
                  <v-card-text>
                    <v-radio-group
                      v-model="bookingResponse.status"                      
                      class="status-radio-group">
                      <v-radio
                        label="Acceptera"
                        color="green"
                        value="Accepted"
                        class="status-radio"
                        ></v-radio> 
                      <v-radio
                        label="Neka"
                        color="red darken-3"
                        value="Rejected"
                        class="status-radio"
                        ></v-radio>
                      <v-radio
                        label="Ohanterad"
                        color="gray darken-3"
                        value="Unhandled"
                        class="status-radio"
                        ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </v-col>             
            </v-row>                 
          </v-card-text>
          <v-col>
            <v-card-actions>                  
              <v-btn
                @click="saveBooking()"
                color="primary"
                elevation="1"
                large
                >
                Spara
              </v-btn>

              <v-btn
                @click="redirectBack()"
                color="secondary"
                elevation="1"
                large
                >
                Avbryt
              </v-btn>
            </v-card-actions>
          </v-col>       
        </v-card>
      </v-col>
    </v-row>

    <BookingCalendar
      :roomsWithEventsProp="roomsWithEvents"
      @emitGetBookings="getBookings"
      @emitSelectedValue="setSelectedValue"
    />

    <v-dialog v-model="addCustomerDialog">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row class="d-flex flex-column">
            <v-col>
              <CustomerForm
                :customer= "newCustomer"
                @closedDialog="addCustomerDialog = false"
                @formSaved="(customerId) => createdCustomer(customerId)"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import BookingCalendar from "@/components/BookingCalendar.vue";
import OccurranceExpPanelv2 from "@/components/OccurranceExpPanelv2.vue";
import CustomerForm from "@/components/CustomerForm.vue";

export default {
  props: {
    bookingId: { type: String, default: () => "" },
    fromComponent: {type: String, default: () => null}
  },

  components: {
    BookingCalendar,
    OccurranceExpPanelv2,
    CustomerForm,
  },

  computed:{
    newCustomer(){
      return this.bookingResponse.customerInfo ?
       JSON.parse(this.bookingResponse.customerInfo) : null;
    },

    unregisteredInfo(){
      return {
        "Namn" : this.bookingResponse.contactName,
        "Email" : this.bookingResponse.contactEmail
      }
    },

    newCustomerInfo(){
      return {
        "Namn" : this.bookingResponse.contactName,
        "Email" : this.bookingResponse.contactEmail,
        ...JSON.parse(this.bookingResponse.customerInfo),
        
      }
    },
  },

  data() {
    return {
      roomsResponse: [],
      activitiesResponse: [],
      bookingResponse: {},
      bookingsResponse: [],
      customersResponse: [],
      customerResponse: null,
      customerInfo: null, 

      editBookingResponse: {},
      editOccurrancesResponse: {},
      sendMailResponse: {},

      booking: {},
      bookings: [],

      bookingModel: {},

      roomsWithEvents: [],

      selectedValue: "",

      focusedEventColors: [
        "red darken-2",
        "pink darken-2",
        "purple darken-2",
        "indigo darken-2",
        "light-blue darken-2",
        "teal darken-2",
        "lime darken-2",
        "amber darken-2",
        "deep-orange darken-2",
      ],
      unfocusedEventColors: [
        "red lighten-4",
        "pink lighten-4",
        "purple lighten-4",
        "indigo lighten-4",
        "light-blue lighten-4",
        "teal lighten-4",
        "lime lighten-4",
        "amber lighten-4",
        "deep-orange lighten-4",
      ],

      isError: false,
      responseError: [],

      addCustomerDialog: false,
    };
  },

  created() {
    this.$watch(
      () => this.$route.params,
      async () => {
        await this.getActivities();
        await this.getRooms();
        await this.getCustomers();
        await this.getBooking();
        await this.getCustomer(this.bookingResponse.customerId);
        this.booking = { ...this.bookingResponse, eventClass: "Focused" };
        this.methodChain();
      },
      { immediate: true }
    );
  },


  beforeMount() {
    this.selectedValue = this.$moment().startOf("week").format("YYYY-MM-DD");
  },

  watch: {

    bookingModel: {
      deep: true,
      handler() {
        this.updatedBookingModel();
      },
    },

    customerResponse() {
      if(this.customerResponse)
        this.customer = this.customerResponse;
    },

    selectedValue() {
      this.methodChain();
    },
  },

  methods: {
    async methodChain() {
      this.rooms = [...this.roomsResponse];
      this.mapToRoomsWithEvents();
      this.bookingToBookingModel();
      this.mapCustomerInfo();
      await this.getBookings();
      this.bookings = this.bookingsResponse.map((x) => ({
        ...x,
        eventClass: "Unfocused",
      }));
      this.bookings.push(this.booking);
      await this.fillEvents();
    },

    async getBooking() {
      await axios
        .get(`api/booking/${this.$route.params.bookingId}`)
        .then((response) => {
          this.bookingResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getBookings() {
      await axios
        .get("api/booking", {
          params: {
            fromDate: `${this.$moment(this.selectedValue)
              .startOf("week")
              .format("YYYY-MM-DD")}T00:00:00`,
            toDate: `${this.$moment(this.selectedValue)
              .add(1, "w")
              .startOf("week")
              .format("YYYY-MM-DD")}T23:59:59`,
            statuses: "Accepted",
            allCustomers: true
          },
        })
        .then((response) => {
          // If bookingResponse is accepted then remove that booking from bookingsResponse to avoid duplication.
          var result = response.data.items.find((b) => {
            return b.id === this.booking.id;
          });
          if (result) {
            response.data.items = response.data.items.filter(
              (b) => b.id != this.booking.id
            );
          }
          this.bookingsResponse = response.data.items;
        });
    },

    async getRooms() {
      await axios
        .get("api/room")
        .then((response) => {
          this.roomsResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getActivities() {
      await axios
        .get("api/activity")
        .then((response) => {
          this.activitiesResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCustomers() {
      await axios
        .get("api/customer/allnames")
        .then((response) => {
          this.customersResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getCustomer(customerId){
      if(customerId){
        await axios
        .get(`api/customer/${customerId}`)
        .then((response) => {
          this.customerResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
      }   
    },

    async mapToRoomsWithEvents() {
      this.roomsWithEvents = this.rooms.map((x) => ({
        ...x,
        events: [],
      }));
    },

    async fillEvents() {
      if (!this.roomsWithEvents || !this.bookings) return; //If either of rooms or bookings hasn't been retrieved yet then do nothing.

      let customers = [...this.customersResponse];
      this.roomsWithEvents = this.roomsWithEvents.map((x) => ({
        ...x,
        events: this.bookings
          .filter((b) => b.occurrances.some((o) => o.roomId == x.id)) // Select all bookings with occurrances with roomid same as the id of the room being iterated.
          .map((booking) =>
            booking.occurrances // For every booking selected iterate on occurrances...
              .filter((o) => o.roomId == x.id) //Filter so only occurrance with correct roomid gets mapped.
              .map((occurrance) => {
                return {
                  // ...and create an event-object for every occurrance to create an event.
                  bookingModel: booking,
                  roomId: occurrance.roomId,
                  activityId: occurrance.activityId,
                  activityName: occurrance.activity
                    ? occurrance.activity.name
                    : "",                
                  name:
                    booking.customerId == 0
                      ? ""
                      : isNaN(booking.customerId) == false
                      ? customers.find(
                          (customer) => customer.id == booking.customerId
                        ).name
                      : "",
                  start: this.$moment(occurrance.startTime).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                  end: this.$moment(occurrance.endTime).format(
                    "YYYY-MM-DD HH:mm"
                  ),
                  notes: booking.notes,
                  color:
                    booking.eventClass == "Focused"
                      ? this.focusedEventColors[occurrance.activityId - 1]
                      : this.unfocusedEventColors[occurrance.activityId - 1],
                  eventClass: booking.eventClass,
                };
              })
          )
          .flat(), // From pagination
      }));
    },

    setSelectedValue(value) {
      this.selectedValue = value;
    },

    allowedStep: (m) => m % 15 === 0,

    addOccurrance(startTime = "", endTime = "") {
      this.bookingModel.occurrances.push({
        id: null,
        bookingId: null,
        roomId: this.roomId,
        room: {},
        activityId: null,
        activity: {},
        date: "",
        startTime: startTime,
        endTime: endTime,
        startTimeDialog: false,
        endTimeDialog: false,
        dateDialog: false,
      });
    },

    removeOccurrance(index) {
      this.bookingModel.occurrances.splice(index, 1);
    },

    mapToBookingModel() {
      this.bookingModel = this.bookingResponse;
    },

    mapToBooking() {
      this.booking = [...this.bookingResponse];
    },

    bookingToBookingModel() {
      var occurrances = (this.booking.occurrances ?? []).map((x) => {
        var startTime = this.$moment(x.startTime);
        var endTime = this.$moment(x.endTime);
        return {
          ...x,
          date: startTime.format("YYYY-MM-DD"),
          startTime: startTime.isValid() ? startTime.format("HH:mm") : "",
          endTime: endTime.isValid() ? endTime.format("HH:mm") : "",
          startTimeDialog: false,
          endTimeDialog: false,
          dateDialog: false,
          repeat: 0,
        };
      });
      this.bookingModel = {
        ...{
          occurrances: [],
          id: null,
          customerId: null,
          contactName: null,
          contactEmail: null,
          notes: null,
        },
        ...this.booking,
        occurrances,
      };
    },

    bookingModelToBooking() {
      var occurrances = (this.bookingModel.occurrances ?? []).map((x) => {
        var startTime = this.$moment(`${x.date}T${x.startTime}`);
        var endTime = this.$moment(`${x.date}T${x.endTime}`);
        var roomId = x.roomId;
        var room = x.room;
        var activityId = x.activityId;
        var activity = x.activity;
        var bookingId = x.bookingId;
        var id = x.id;
        return {
          roomId: roomId,
          room: room,
          activityId: activityId,
          activity: activity,
          bookingId: bookingId,
          id: id,
          startTime: startTime.isValid()
            ? startTime.format("YYYY-MM-DD HH:mm")
            : "",
          endTime: endTime.isValid() ? endTime.format("YYYY-MM-DD HH:mm") : "",
        };
      });
      this.booking = {
        ...{
          occurrances: [],
          id: null,
          customerId: null,
          contactName: null,
          contactEmail: null,
          notes: null,
        },
        ...this.bookingModel,
        occurrances,
      };
    },

    updatedBookingModel() {
      this.booking = {};
      this.bookingModelToBooking();
      this.bookings = this.bookingsResponse.map((x) => ({
        ...x,
        eventClass: "Unfocused",
      }));
      this.bookings.push(this.booking);
      this.roomsWithEvents = [];
      this.mapToRoomsWithEvents();
      this.fillEvents();
    },

    triggerAddOccurrance() {
      this.$refs.occurrancePanel.addOccurrance();
    },

    occurrancesUpdated(updatedOccurrances) {
      this.bookingModel.occurrances = updatedOccurrances;
    },

    async saveBooking() {
      await this.editOccurrances();
      if (this.editOccurrancesResponse.status === 201) {
        await this.editBooking();
        if (
          this.editBookingResponse.status === 200 ||
          this.editBookingResponse.status === 201
        ) {
          await this.sendMail();
          if (this.sendMailResponse.status == 200) {
            this.redirectBack();
          }
        }
      }
    },

    async editBooking() {
      var bookingCommand = {
        ...this.booking,
        status: this.bookingResponse.status,
        customerId: this.booking.customerId ? this.booking.customerId : null,
      };

      await axios
        .put(`api/booking/${bookingCommand.id}`, bookingCommand)
        .then((x) => {
          this.editBookingResponse = x;
          return x.status;
        })
        .catch((error) => {
          this.editBookingResponse = error.response;
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          this.isError = true;
          console.error(error);
        });
    },

    async editOccurrances() {
      var occurrancesCommand = this.booking.occurrances
        .map((x) => ({ ...x, bookingId: this.booking.id }))
        .map((x) => [
          {
            ...x,
            startTime: this.$moment(x.startTime).format("YYYY-MM-DDTHH:mm"),
            endTime: this.$moment(x.endTime).format("YYYY-MM-DDTHH:mm"),
          },
        ])
        .flat(1);

      await axios
        .post(`api/bookingoccurrance/${this.booking.id}`, occurrancesCommand)
        .then((x) => {
          this.editOccurrancesResponse = x;
          return x.status;
        })
        .catch((error) => {
          this.editOccurrancesResponse = error.response;
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          this.isError = true;
          console.error(error);
        });
    },

    async sendMail() {
      let mailTemplateId = "d-6a693a409ecf4f5d8b89cc042e331e95"; //TODO Change Template
      await axios
        .post(
          `api/booking/sendmail/${this.editBookingResponse.data.id}/${mailTemplateId}`
        )
        .then((x) => {
          this.sendMailResponse = x;
          return x.status;
        })
        .catch((error) => {
          console.error(error);
          this.sendMailResponse = error.response;
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          }
          else if(typeof error.response.data === 'string'){
            this.responseError.push(error.response.data);
          } else {
            this.responseError = [];          
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          this.isError = true;
          console.error(error);
        });
    },

    async createdCustomer(customerId) {
      await this.getCustomers();
      this.addCustomerDialog = false;
      this.bookingModel.customerId = customerId;
      await this.getCustomer(customerId);
      this.mapCustomerInfo();
    },

    mapCustomerInfo(){
      let startingCustomer;
      if(this.customerResponse){
        startingCustomer = this.customerResponse;
      }
      else if(this.bookingResponse.customerInfo){
        startingCustomer = JSON.parse(this.bookingResponse.customerInfo);
      }
      if(startingCustomer){
          this.customerInfo = {
          "Namn" : this.bookingResponse.contactName,
          "Email" : this.bookingResponse.contactEmail,
          "Kundnamn" : startingCustomer.name,
          "Org-/Personnummer": startingCustomer.orgOrPersonalNumber, 
          "Kundkontakt": startingCustomer.customerContact, 
          "Kundmail": startingCustomer.email, 
          "Telefonnummer": startingCustomer.phoneNumber, 
          "Adress": startingCustomer.address, 
          "Postnummer": startingCustomer.postalcode, 
          "Stad": startingCustomer.city
        }
      }
      else{
          this.customerInfo = {
            "Namn" : this.bookingResponse.contactName,
            "Email" : this.bookingResponse.contactEmail,
        }
      }   
      //Remove empty properties.
      Object.keys(this.customerInfo).forEach(key => {
        if(this.customerInfo[key] === ""){
          delete this.customerInfo[key];
        }
      })
    },

    redirectBack(){
      if(this.$route.params.fromComponent){
              this.$router.push({name: this.fromComponent});
            }
            else{
              this.$router.push("/bookings/handle");
            }
    },
  },
};
</script>

<style>
.status-radio-group .v-input--radio-group__input{
  justify-content: space-around;
}
.status-radio-group .status-radio{
  margin-left: 2rem;
}

.status-radio-group .status-radio .v-label{
  font-size: 1.1rem;
}

</style>
