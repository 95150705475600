<template>
  <v-menu
    v-model="selectedOpen"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-x
  >
    <v-card v-if="isLoggedIn" flat>
      <v-toolbar :color="selectedEvent.color" dark>
        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        {{ selectedEvent.roomId }}
        <v-spacer></v-spacer>
        {{ selectedEvent.activityName }}
        <v-spacer></v-spacer>
        {{ selectedEvent.start }} - {{ selectedEvent.end }}
        <v-spacer></v-spacer>
        {{ selectedEvent.notes }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="selectedOpen = false"> Avbryt </v-btn>
        <v-btn color="primary" @click="openEditDialog(selectedEvent)">
          Redigera
        </v-btn>
        <v-btn color="error" @click="openDeleteDialog"> Ta bort </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else flat>
      <v-toolbar :color="selectedEvent.color" dark>
        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        {{ selectedEvent.activityName }}
        <v-spacer></v-spacer>
        {{ selectedEvent.start }} - {{ selectedEvent.end }}
        <v-spacer></v-spacer>
        {{ selectedEvent.notes }}
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="selectedOpen = false"> Avbryt </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "EventDialog",

  props: {
    selectedEventProp: { type: Object, default: () => {} },
    selectedElementProp: {
      type: [HTMLParagraphElement, HTMLDivElement],
      default: () => {},
    },
    selectedOpenProp: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedEvent: {},
      selectedElement: {},
      selectedOpen: false,
    };
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },

  watch: {
    selectedEventProp() {
      this.selectedEvent = this.selectedEventProp;
    },

    selectedElementProp() {
      this.selectedElement = this.selectedElementProp;
    },

    selectedOpenProp() {
      this.selectedOpen = this.selectedOpenProp;
    },
  },

  methods: {
    openEditDialog(selectedEvent) {
      this.dialog = {
        ...this.dialog,
        bookingModel: selectedEvent.bookingModel,
        visible: true,
      };

      this.$emit("openBookingForm", this.dialog);
    },

    openDeleteDialog() {
      this.$emit("openDeleteDialog");
    },
  },
};
</script>
