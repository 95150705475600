<template>
  <v-card>
    <v-card-title> </v-card-title>
    <v-form @submit.prevent="onSubmit">
      <v-card-text>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <OccurranceExpPanel
                  ref="occurrancePanel"
                  v-model="bookingModel.occurrances"
                  :rooms="rooms"
                  :activities="activities"
                  @occurrancesUpdated="occurrancesUpdated"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-btn
                  color="primary"
                  elevation="2"
                  @click="triggerAddOccurrance()"
                  >Lägg till tid</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="bookingModel.contactName"
              label="Namn"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="bookingModel.contactEmail"
              label="Email"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="bookingModel.notes"
              outlined
              dense
              hide-details
            >
              <template v-slot:label>
                <div>Noteringar <small>(valfri)</small></div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="isAdmin">
          <v-col cols="6">
            <v-select
              v-model="bookingModel.customerId"
              :items="customersResponse"
              label="Företag/lag*"
              item-text="name"
              item-value="id"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col cols="6"> </v-col>
          <v-col>
            <v-alert :value="isError" type="error">
              <div
                v-for="(error, index) in responseError"
                :key="`error_${index}`"
              >
                {{ error }}
              </div>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12">
            <v-text-field
              v-model="customerInfo.name"
              label="Företag, organisation eller lag"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col>
            <v-checkbox v-model="newCustomer" label="Ny kund? Klicka här">
            </v-checkbox>
          </v-col>

          <v-col v-if="newCustomer" cols="12">
            <v-text-field
              v-model="customerInfo.orgOrPersonalNumber"
              label="Organisations- eller personnummer"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="newCustomer" cols="12">
            <v-text-field
              v-model="customerInfo.customerContact"
              label="Namn på företagskontakt"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="newCustomer" cols="12">
            <v-text-field
              v-model="customerInfo.email"
              label="Företags email"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="newCustomer" cols="12">
            <v-text-field
              v-model="customerInfo.phoneNumber"
              label="Telefonnummer"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="newCustomer" cols="6">
            <v-text-field
              v-model="customerInfo.address"
              label="Adress"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="newCustomer" cols="6">
            <v-text-field
              v-model="customerInfo.postalcode"
              label="Postnummer"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col v-if="newCustomer" cols="12">
            <v-text-field
              v-model="customerInfo.city"
              label="Stad"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <v-col>
            <v-alert :value="isError" type="error">
              <div
                v-for="(error, index) in responseError"
                :key="`error_${index}`"
              >
                {{ error }}
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="onCloseDialog()"> Avbryt </v-btn>
        <v-btn color="primary" type="submit"> Spara </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import OccurranceExpPanel from "./OccurranceExpPanel.vue";

export default {
  name: "BookingForm",

  components: {
    OccurranceExpPanel,
  },

  props: {
    rooms: { type: Array, default: () => [] },
    booking: { type: Object, default: () => {} },
    customer: { type: Object, default: () => {} },
  },
  data() {
    return {
      occurranceHeaders: [
        { text: "Date", align: "left" },
        { text: "Start time", align: "left" },
        { text: "End time", align: "left" },
      ],
      newCustomer: false,
      activities: [],
      bookingModel: {},
      customerModel: {},
      customerResponse: {},
      bookingResponse: {},
      occurrancesResponse: {},
      sendMailResponse: {},
      mailUnsent: false,
      customersResponse: [],
      deleteResponse: {},

      customerInfo: {},

      isError: false,
      responseError: [],
    };
  },

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },

    bookingSuccessMessage(){
      return this.isAdmin ? "Bokningen sparad" : "Bokingen inskickad";
    }
  },

  beforeMount() {
    this.getActivities();
    this.getCustomers();
  },

  mounted() {
    if (this.booking) {
      this.setBookingModel();
      this.setCustomerModel();
    }
  },

  watch: {
    booking() {
      this.setBookingModel();
      this.setCustomerModel();
    },
  },

  methods: {
    getRepeatDays(occurrance) {
      var firstStartTime = this.$moment(
        occurrance.date + " " + occurrance.startTime
      );
      var firstEndtime = this.$moment(
        occurrance.date + " " + occurrance.endTime
      );
      var days = Array.from({ length: occurrance.repeat })
        .map((_, index) => index + 1)
        .map((weekAdd) => ({
          startTime: firstStartTime.clone().add(weekAdd, "week"),
          endTime: firstEndtime.clone().add(weekAdd, "week"),
        }));
      return days;
    },

    setBookingModel() {
      var occurrances = (this.booking.occurrances ?? []).map((x) => {
        var startTime = this.$moment(x.startTime);
        var endTime = this.$moment(x.endTime);
        return {
          ...x,
          date: startTime.format("YYYY-MM-DD"),
          startTime: startTime.isValid() ? startTime.format("HH:mm") : "",
          endTime: endTime.isValid() ? endTime.format("HH:mm") : "",
          startTimeDialog: false,
          endTimeDialog: false,
          dateDialog: false,
          repeat: 0,
          roomId: x.roomId
        };
      });
      this.bookingModel = {
        ...{
          occurrances: [],
          id: null,
          customerId: null,
          customerContact: null,
          contactEmail: null,
          notes: null,
        },
        ...this.booking,
        occurrances,
      };
    },

    setCustomerModel() {
      this.customerModel = {
        ...{
          name: null,
          email: null,
          customerContact: null,
          address: null,
          postalCode: null,
          city: null,
          phoneNumber: null,
          orgOrPersonalNumber: null,
        },
        ...this.booking.customer,
      };
    },

    getCustomers() {
      axios
        .get("api/customer/allnames")
        .then((response) => {
          this.customersResponse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getActivities() {
      axios.get("api/activity").then((response) => {
        this.activities = response.data;
      });
    },

    onCloseDialog() {
      this.resetCustomerInfo();
      this.isError = false;
      this.responseError = [];
      this.$emit("closedDialog");
    },

    resetCustomerInfo() {
      this.customerInfo = {};
      this.newCustomer = false;
    },

    async onSubmit() {
      await this.postBooking();
      if (
        this.bookingResponse.status === 200 ||
        this.bookingResponse.status === 201
      ) {
        await this.postOccurrances();
        if (this.occurrancesResponse.status === 201) {
          await this.sendMail(); 
          this.isError = false;
          this.mailUnsent = this.sendMailResponse.status !== 200;
          this.$emit("formSaved", this.bookingSuccessMessage , this.mailUnsent);
          this.resetCustomerInfo();
        } else {
          this.deleteIncorrectBooking();
          this.isError = true;
        }
      } else {
        this.isError = true;
      }
    },

    async sendMail() {   
      let mailTemplateId = "d-2230d242e3354ed79d9825e34cea97ec";
      await axios
        .post(
          `api/booking/sendmail/${this.bookingResponse.data.id}/${mailTemplateId}`
        )
        .then((x) => {
          this.sendMailResponse = x;
          return x.status;
        })
        .catch((error) => {
          this.sendMailResponse = error.response;
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          this.isError = true;
          console.error(error);
        });
    },

    async postBooking() {
      var bookingModel = {
        ...this.bookingModel,
        customerInfo: JSON.stringify(this.customerInfo)
      };
      var axiosRequest = null;
      if (bookingModel.id) {
        axiosRequest = axios.put(
          `api/booking/${bookingModel.id}`,
          bookingModel
        );
      } else {
        axiosRequest = axios.post(`api/booking/`, bookingModel);
      }
      await axiosRequest
        .then((response) => {
          this.bookingResponse = response;
          return response.status;
        })
        .catch((error) => {
          this.bookingResponse = error.response;
          console.log(error);
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          console.error(error);
        });
    },

    async postOccurrances() {
      var newOccurranceModel = this.bookingModel.occurrances
        .map((x) => ({ ...x, bookingId: this.bookingResponse.data.id }))
        .map((x) => [
          {
            ...x,
            startTime: x.date + "T" + x.startTime,
            endTime: x.date + "T" + x.endTime,
          },
          ...this.getRepeatDays(x).map((r) => ({
            ...x,
            startTime: r.startTime.format("YYYY-MM-DDTHH:mm"),
            endTime: r.endTime.format("YYYY-MM-DDTHH:mm"),
          })),
        ])
        .flat(1);

      await axios
        .post(
          `api/bookingoccurrance/${this.bookingResponse.data.id}`,
          newOccurranceModel
        )
        .then((response) => {
          this.occurrancesResponse = response;
          return response.status;
        })
        .catch((error) => {
          this.occurrancesResponse = error.response;
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          console.error(error);
        });
    },

    deleteIncorrectBooking() {
      axios
        .delete(`api/booking/delete/${this.bookingResponse.data.id}`)
        .then((response) => {
          this.deleteResponse = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },


    triggerAddOccurrance() {
      this.$refs.occurrancePanel.addOccurrance();
    },

    occurrancesUpdated(updatedOccurrances) {
      this.BookingModel.occurrances = updatedOccurrances;
    },
  },
};
</script>
