<template>
  <v-app>
    <v-navigation-drawer v-if="isLoggedIn" app v-model="drawer">
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            router
            :to="item.route"
          >
            <v-list-item-icon>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title
              ><span class="primary--text font-weight-medium">
                {{ item.title }}
              </span></v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dark elevation="2" color="primary" app v-if="isLoggedIn">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        ><span
          style="cursor: pointer"
          @click="$router.push('/bookings/calendar')"
          >Boka</span
        ></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn
        style="
          border-style: solid !important;
          border-width: 1px !important;
          border-color: #cd8d37 !important;
        "
        color="secondary"
        v-if="isLoggedIn"
        @click="logOut()"
        >Logga ut</v-btn
      >
      <v-btn v-else text to="/admin">Logga in</v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid class="justify-center">
        <router-view></router-view>
      </v-container>

      <!-- <ScheduleBox ref="test" /> -->
    </v-main>
  </v-app>
</template>

<script>
// import NavBar from "./components/NavBar";
// import NavDrawer from "./components/NavDrawer";

export default {
  name: "App",

  components: {
    // NavBar,
    // NavDrawer,
  },

  data() {
    return {
      drawer: false,
      items: [
        {
          icon: "mdi-calendar-outline",
          title: "Kalender",
          route: "/bookings/calendar",
        },
        {
          icon: "mdi-list-box-outline",
          title: "Hantera bokningar",
          route: "/bookings/handle",
        },
        {
          icon: "mdi-account-cash-outline",
          title: "Fakturering",
          route: "/bookings/billing",
        },
        {
          icon: "mdi-account-multiple-outline",
          title: "Hantera kunder",
          route: "/customers/handle",
        },
        {
          icon: "mdi-shield-account-outline",
          title: "Hantera admins",
          route: "/user/handle",
        },
      ],
    };
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },

  mounted() {
    this.$store.dispatch("loggedInChecker");
  },

  methods: {
    async logOut() {
      await this.$store.dispatch("LogOut");
    },
  },
};
</script>

<style>
@import "./assets/styles/style.css";
</style>
