<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row class="d-flex align-center">
          <v-col class="d-flex justify-center">
            <v-text-field
              v-model="searchString"
              label="Sök"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="getCustomers()">
              <v-icon>mdi-account-search</v-icon></v-btn
            >
          </v-col>
          <v-col class="d-flex justify-center">
            <v-btn color="primary" dark @click="showAddDialog()"
              >Lägg till kund</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :items="customersResponse.items"
        :headers="headers"
        :search="searchString"
        :options.sync="customersPagingOptions"
        :server-items-length="customersResponse.totalCount"
        @click:row="handleRowClick($event, clickedRow)"
      >
        <!-- <template v-slot:[`item.controls`]="{ props }">
              <v-btn text color="primary" @click="moreInfo(props.item)">
                More Info
              </v-btn>
            </template> -->
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog.showForm" persistent max-width="600px">
      <CustomerForm
        :customer="dialog.customerModel"
        @formSaved="getCustomers(), (dialog.showForm = false)"
        @closedDialog="dialog.showForm = false"
        @closeInfoDialog="dialog.showInfo = false"
      />
    </v-dialog>

    <v-dialog v-model="dialog.showInfo">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialog.customerModel.name }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div>
                <div class="text-subtitle-2">Organisations/personnummer</div>
                <div class="text-body-1">
                  {{ dialog.customerModel.orgOrPersonalNumber }}
                </div>
              </div>

              <div>
                <div class="text-subtitle-2">Standard pris:</div>
                <div class="text-body-1">
                  {{ dialog.customerModel.defaultPrice }} SEK
                </div>
              </div>
            </v-col>

            <v-col>
              <div class="text-h6">Adress</div>

              <div class="text-body-1">
                <span>
                  {{ dialog.customerModel.address }}
                </span>
                <br />
                <span>
                  {{ dialog.customerModel.postalcode }}
                </span>
                <span>
                  {{ dialog.customerModel.city }}
                </span>
              </div>
            </v-col>
            <v-col>
              <div class="text-h6">Kontakt person</div>

              <div class="text-body-1">
                <span>
                  {{ dialog.customerModel.customerContact }}
                </span>
              </div>

              <div>
                <div class="text-subtitle-2">Telefon nummer:</div>
                <div class="text-body-1">
                  {{ dialog.customerModel.phoneNumber }}
                </div>
              </div>

              <div>
                <div class="text-subtitle-2">Email:</div>
                <div class="text-body-1">
                  {{ dialog.customerModel.email }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="closeInfoDialog()">Avbryt</v-btn>
          <v-btn color="primary" @click="showAddDialog()">Redigera</v-btn>
          <v-btn color="error" @click="deleteDialog = true">Ta bort</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="600px">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="text-body-1">
            <span> Är du säker på att du vill ta bort kunden </span>
            <span class="font-weight-bold">
              {{ dialog.customerModel.name }}
            </span>
            <span>?</span>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="deleteDialog = false">
            Avbryt
          </v-btn>
          <v-btn color="error" @click="deleteCustomer()"> Ta bort </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import CustomerForm from "./CustomerForm.vue";

export default {
  name: "CustomerPagination",

  components: {
    CustomerForm,
  },

  data() {
    return {
      customersResponse: [],
      customersPagingOptions: { page: 1, itemsPerPage: 15 },
      headers: [
        // { text: "Id", align: "left", value: "id", sortable: false },
        { text: "Kund namn", align: "left", value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        // { text: "Default Price", value: "defaultPrice", sortable: false },
        { text: "Kontakt person", value: "customerContact", sortable: false },
        // { text: "Address", value: "address", sortable: false },
        // { text: "Postalcode", value: "postalcode", sortable: false },
        // { text: "City", value: "city", sortable: false },
        { text: "Telefon nummer", value: "phoneNumber", sortable: false },
        // {
        //   text: "OrgOrPersonalNumber",
        //   value: "orgOrPersonalNumber",
        //   sortable: false,
        // },
      ],
      searchString: "",
      dialog: {
        showInfo: false,
        showForm: false,
        customerModel: {
          id: null,
          name: null,
          contanctName: null,
          email: null,
          address: null,
          postalcode: null,
          city: null,
          phoneNumber: null,
          orgOrPersonalNumber: null,
        },
      },
      clickedRow: null,
      deleteDialog: false,
    };
  },

  watch: {

    customersPagingOptions: {
      deep: true,
      handler() {
        this.getCustomers();
      },
    },
  },

  methods: {
    getCustomers() {
      axios
        .get("api/customer", {
          params: {
            "paging.pageIndex": this.customersPagingOptions.page - 1,
            "paging.pageSize": this.customersPagingOptions.itemsPerPage,
            search: this.searchString,
            "paging.getAll": this.customersPagingOptions.itemsPerPage === -1 ? true : false
          },
        })
        .then((response) => {
          this.customersResponse = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteCustomer() {
      axios
        .delete(
          `api/customer/${this.dialog.customerModel.id}`
        )
        .then(() => {
          this.deleteDialog = false;
          this.dialog.showInfo = false;
          this.getCustomers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showAddDialog() {
      this.dialog = {
        ...this.dialog,
        showForm: true,
      };
    },

    moreInfo() {},

    handleRowClick(clickedRow) {
      this.dialog = {
        ...this.dialog,
        showInfo: true,
        customerModel: clickedRow,
      };
    },

    closeInfoDialog() {
      this.dialog = {
        showInfo: false,
        customerModel: {
          id: null,
          name: null,
          contanctName: null,
          email: null,
          address: null,
          postalcode: null,
          city: null,
          phoneNumber: null,
          orgOrPersonalNumber: null,
        },
      };
    },
  },
};
</script>
