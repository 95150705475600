<template>
  <v-row class="justify-center">
    <v-col cols="12" sm="8" md="6" lg="4" xl="3">
      <v-card>
        <v-card-title>
          <span class="text-h5">Registrera admin</span>
        </v-card-title>
        <v-form @submit.prevent="onSubmit">
          <v-card-text>
            <v-col cols="12">
              <v-text-field
                v-model="registerModel.name"
                label="Namn"
                filled
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="registerModel.userName"
                label="Användarnamn"
                filled
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="registerModel.password"
                label="Lösenord"
                type="password"
                filled
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="registerModel.confirmPassword"
                label="Bekräfta lösenord"
                type="password"
                filled
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-alert :value="isError" type="error">
                <div
                  v-for="(error, index) in responseError"
                  :key="`error_${index}`"
                >
                  {{ error }}
                </div>
              </v-alert>
            </v-col>
          </v-card-text>

          <v-card-actions>
            <v-btn type="submit" color="primary">Skapa admin</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
    <v-dialog
      v-model="showCreatedDialog"
      persistent
      width="300px"
      height="200px"
    >
      <v-card>
        <v-card-title>
          <p>Title</p>
        </v-card-title>
        <v-card-text>
          New admin was created: {{ registerModel.userName }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="OnClick()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  name: "RegisterForm",

  data() {
    return {
      registerModel: {
        name: "",
        userName: "",
        password: "",
      },
      confirmPassword: "",
      isError: false,
      responseError: null,
      showCreatedDialog: null,
    };
  },

  methods: {
    async onSubmit() {
      this.registerModel = {
        ...this.registerModel,
        isAdmin: true,
      };
      await axios
        .post("api/auth/register", this.registerModel)
        .then(() => {
          this.isError = false;
          this.showCreatedDialog = true;
        })
        .catch((error) => {
          if (Array.isArray(error.response.data)) {
            this.responseError = error.response.data;
          } else {
            this.responseError = [];
            Object.keys(error.response.data).forEach((p) => {
              if (Array.isArray(error.response.data[p])) {
                error.response.data[p].forEach((errorMessage) => {
                  this.responseError.push(errorMessage);
                });
              }
            });
          }
          this.isError = true;
        });
    },

    OnClick() {
      this.showCreatedDialog = false;
      this.$router.go();
    },
  },
};
</script>
