<template>
  <div class="flex-column justify-space-around" style="width: 100%">
    <BookingPagination
      :getBookingsTrigger="getBookingsTrigger"
      @emitItem="handleEmit"
    />

    <v-dialog v-model="dialog.visible">
      <BookingHandle
        :bookingModel="dialog.bookingModel"
        @closeDialog="closeDialog"
        @openEditDialog="openEditDialog"
      />
    </v-dialog>

    <v-dialog v-model="editDialog.visible">
      <BookingForm
        :booking="editDialog.bookingModel"
        :rooms="rooms"
        @closedDialog="closeEditDialog"
        @formSaved="closeEditDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import BookingPagination from "@/components/BookingPagination.vue";
import BookingHandle from "@/components/BookingHandle.vue";
import BookingForm from "@/components/BookingForm.vue";
import axios from "axios";

export default {
  components: {
    BookingPagination,
    BookingHandle,
    BookingForm,
  },

  data() {
    return {
      dialog: {},

      editDialog: {},

      rooms: [],

      getBookingsTrigger: false,
    };
  },

  beforeMount() {
    axios.get("api/room").then((response) => {
      this.rooms = response.data;
    });
  },

  watch: {},

  methods: {
    handleEmit(emitedItem) {
      this.dialog = emitedItem;
      this.editDialog = {
        bookingModel: emitedItem.bookingModel,
        visible: false,
      };
    },

    closeDialog() {
      this.dialog.visible = false;
      this.getBookingsTrigger = !this.getBookingsTrigger;
    },

    openEditDialog() {
      this.closeDialog();
      this.editDialog.visible = true;
    },

    closeEditDialog() {
      this.editDialog.visible = false;
      this.getBookingsTrigger = !this.getBookingsTrigger;
    },
  },
};
</script>
